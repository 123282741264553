
import { computed, defineComponent } from "vue";
import { useDatabase } from "./composables/useDatabase";
import { useAuth } from "./composables/useAuth";

export default defineComponent({
  name: "App",
  setup() {
    const { alarmStatus, alarmStatusLoading } = useDatabase();
    const { isDummyUser } = useAuth();
    const translatedAlarmStatus = computed(() =>
      alarmStatus.value ? "EIN" : "AUS"
    );

    return {
      alarmStatus,
      translatedAlarmStatus,
      alarmStatusLoading,
      isDummyUser,
    };
  },
});
