import { getAuth, onAuthStateChanged, User, signOut } from "firebase/auth";
import { ref } from "vue";
import app from "../firebase";
import { computed } from "@vue/reactivity";

const currentUser = ref<User | undefined>();
const isDummyUser = computed(() =>
  /@emilheusser\.ch$/.test(currentUser.value?.email ?? "")
);

const auth = getAuth(app);

onAuthStateChanged(auth, (user) => {
  if (user) {
    currentUser.value = user;
  } else {
    currentUser.value = undefined;
  }
});

function logout() {
  return signOut(auth);
}

export function useAuth() {
  return { currentUser, logout, isDummyUser };
}
