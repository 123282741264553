import { StatusLogItem } from "../../../sharedTypes/types";
import {
  getDatabase,
  ref as dbRef,
  push,
  set,
  onChildAdded,
  limitToLast,
  query,
} from "firebase/database";
import { computed, ref } from "vue";
import app from "../firebase";
import { useAuth } from "./useAuth";

const database = getDatabase(app);

const alarmLogRef = dbRef(database, "alarm");
const sirenLogRef = dbRef(database, "siren");
const lastChildAlarmLogRef = query(alarmLogRef, limitToLast(1));
const lastChildSirenLogRef = query(sirenLogRef, limitToLast(1));

const { currentUser } = useAuth();
const alarmStatusRef = ref<StatusLogItem>({
  value: false,
  email: "",
  timestamp: "",
});
const alarmStatusLoading = ref(true);
const sirenStatusRef = ref<StatusLogItem>({
  value: false,
  email: "",
  timestamp: "",
});
const sirenStatusLoading = ref(true);

onChildAdded(lastChildAlarmLogRef, (snapshot) => {
  alarmStatusRef.value = snapshot.val() as StatusLogItem;
  alarmStatusLoading.value = false;
});

onChildAdded(lastChildSirenLogRef, (snapshot) => {
  sirenStatusRef.value = snapshot.val() as StatusLogItem;
  sirenStatusLoading.value = false;
});

function setAlarm(value: boolean) {
  const latestStatus = push(alarmLogRef);
  set(latestStatus, {
    value,
    email: currentUser.value?.email,
    timestamp: new Date().toISOString(),
  } as StatusLogItem);
}

function setSiren(value: boolean) {
  const latestStatus = push(sirenLogRef);
  set(latestStatus, {
    value,
    email: currentUser.value?.email,
    timestamp: new Date().toISOString(),
  } as StatusLogItem);
}

const alarmStatus = computed<boolean>({
  get() {
    return alarmStatusRef.value.value;
  },
  set: setAlarm,
});

const sirenStatus = computed<boolean>({
  get() {
    return sirenStatusRef.value.value;
  },
  set: setSiren,
});

export function useDatabase() {
  return {
    alarmStatus,
    sirenStatus,
    alarmStatusLoading,
    sirenStatusLoading,
  };
}
