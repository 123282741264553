import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyANevBnUsbYAZ0hOK2VuTZma1eS7_8NMic",
  authDomain: "emilheusser1.firebaseapp.com",
  databaseURL: "https://emilheusser1.firebaseio.com",
  projectId: "emilheusser1",
  storageBucket: "emilheusser1.appspot.com",
  messagingSenderId: "613239783483",
  appId: "1:613239783483:web:62df9da3f7c591725b5c1e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export function getCurrentUser(): Promise<User> {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      console.log("user", user);
      if (user) {
        resolve(user);
      } else {
        reject();
      }
    });
  });
}

export default app;
