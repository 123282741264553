import { RouteName } from "@/types/types";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { getCurrentUser } from "../firebase";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: RouteName.Status,
    component: () =>
      import(/* webpackChunkName: "status" */ "../views/StatusView.vue"),
    beforeEnter: authNavigationGuard,
  },
  {
    path: "/log",
    name: RouteName.Log,
    component: () =>
      import(/* webpackChunkName: "log" */ "../views/LogView.vue"),
    beforeEnter: authNavigationGuard,
  },
  {
    path: "/login",
    name: RouteName.Login,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/account",
    name: RouteName.Account,
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/AccountView.vue"),
    beforeEnter: authNavigationGuard,
  },
  {
    path: "/invite/:credentials",
    name: RouteName.Invite,
    component: () =>
      import(/* webpackChunkName: "invite" */ "../views/InviteView.vue"),
    beforeEnter: async () => {
      try {
        const user = await getCurrentUser();
        if (user) {
          return { name: RouteName.Status };
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

async function authNavigationGuard() {
  try {
    const user = await getCurrentUser();
    if (!user) {
      throw new Error();
    }
  } catch (error) {
    return { name: RouteName.Login };
  }
}

export default router;
